import axios from "axios";
// 封装axios的post请求

export function fetchGet(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params,{ headers: {
        'Content-Type': 'application/json'
      }},)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export default {
  mockdata(url, params) {
    return fetchPost(url, params);
  }
};