interface lan {
    [key: string]: any,
}

const language:lan = {
    // 简体
    'zh-cn': {
        input_id: '点此输入照片ID',
        report: '举报',
        qrcode: '二维码',
        shoot_time: '拍摄时间',
        address: '地址',
        time_visited: '查看次数',
        download: '下载App',
        share: '分享',
        embed: '嵌入',
        question: '想将这张照片分享到你的社交网络上吗？只需复制以下链接',
        copy_link: '复制链接',
        copy: '复制',
        embed_code: '想在您的网站或博客上嵌入这张照片吗？只需放入下面的嵌入代码，您就完成了！',
        code: '嵌入代码',
        response: '响应式',
        wait_48: '您的举报将在48小时内受理，请尽量提交详细的投诉说明',
        report_desc: '举报描述',
        detail_desc: '请详细描述举报理由',
        call: '联系方式',
        option: '选填',
        submit: '提交',
        remark:'备注',
        cancel: '取消',
        take: '拍摄你的真实照片',
        lan: '经度',
        lon: '纬度',
        device: '手机型号',
        time: '视频时长'
    },
     // 繁体
     'zh': {
        input_id: '點此輸入照片ID',
        report: '舉報',
        qrcode: '二維碼',
        shoot_time: '拍攝時間',
        address: '地址',
        time_visited: '查看次數',
        download: '下載App',
        share: '分享',
        embed: '嵌入',
        question: '想將這張照片分享到你的社交網絡上嗎？只需複制以下鏈接',
        copy_link: '複製鏈接',
        copy: '複製',
        embed_code: '想在您的網站或博客上嵌入這張照片嗎？只需放入下面的嵌入代碼，您就完成了！',
        code: '嵌入代碼',
        response: '響應式',
        wait_48: '您的舉報將在48小時內受理，請盡量提交詳細的投訴說明',
        report_desc: '舉報描述',
        detail_desc: '請詳細描述舉報理由',
        call: '聯繫方式',
        option: '選填',
        submit: '提交',
        remark:'備註',
        cancel: '取消',
        take: '拍攝你的真實照片',
        lan: '經度',
        lon: '緯度',
        device: '手機型號',
        time: '视频时长'
    },
     // 英语
     'en': {
        input_id: 'Click here to enter photo ID',
        report: 'Report',
        qrcode: 'QR Code',
        shoot_time: 'Shooting Date',
        address: 'Address',
        time_visited: 'Views',
        download: 'Download App',
        share: 'Share',
        embed: 'Embed',
        question: 'Want to share this photo to your social media? Simply copy below link!',
        copy_link: 'Copy Link',
        copy: 'Copy',
        embed_code: "Want to embed this photo on your website or blog?Just drop in the embed code below and you're done!",
        code: 'Embed Code',
        response: 'Responsive',
        wait_48: 'Your report will be handled within 48 hours. Please submit your complaint in detail.',
        report_desc: 'Report Description',
        detail_desc: 'Please describe your report in detail',
        call: 'Contact Information',
        option: 'Optional',
        submit: 'Submit',
        remark:'Remark',
        cancel: 'Cancel',
        take: 'Take Your Real Photo',
        lan: 'Longitude',
        lon: 'Latitude ',
        device: 'Device Model',
        time: 'Video Duration'
    },
     // 法语
     'fr': {
        input_id: "Cliquez ici pour entrer une pièce d'identité avec photo",
        report: 'Signaler',
        qrcode: 'QR Code',
        shoot_time: 'Date de prise de vue',
        address: 'Adresse',
        time_visited: 'Vues',
        download: "Télécharger l'application",
        share: 'Partager',
        embed: 'Intégrer',
        question: 'Vous souhaitez partager cette photo sur vos réseaux sociaux ? Copiez simplement le lien ci-dessous!',
        copy_link: 'Copier le lien',
        copy: 'Copie',
        embed_code: "Vous voulez intégrer cette photo sur votre site Web ou votre blog ?Insérez simplement le code d'intégration ci-dessous et vous avez terminé !",
        code: 'Code intégré',
        response: 'Sensible',
        wait_48: 'Votre rapport sera traité dans les 48 heures. Veuillez soumettre votre plainte en détail.',
        report_desc: 'Description du rapport',
        detail_desc: 'Veuillez décrire votre rapport en détail',
        call: 'Coordonnées',
        option: 'Optionnel',
        submit: 'Soumettre',
        remark:'Remarque',
        cancel: 'Annuler',
        take: 'Prenez votre vraie photo',
        lan: 'Longitude',
        lon: 'Latitude',
        device: "Modèle d'appareil",
        time: 'Durée de la vidéo'
    },
    // 葡萄牙
    'pt': {
        input_id: 'Clique aqui para inserir a identificação com foto',
        report: 'Relatório',
        qrcode: 'Código QR',
        shoot_time: 'Data de filmagem',
        address: 'Endereço',
        time_visited: 'Visualizações',
        download: 'Baixar aplicativo',
        share: 'Compartilhar',
        embed: 'Embutir',
        question: 'Quer compartilhar esta foto em suas redes sociais? Basta copiar o link abaixo!',
        copy_link: 'Link de cópia',
        copy: 'cópia de',
        embed_code: 'Quer incorporar esta foto em seu site ou blog?Basta inserir o código de incorporação abaixo e pronto!',
        code: 'Código embutido',
        response: 'Responsivo',
        wait_48: 'Seu relatório será processado em até 48 horas. Envie sua reclamação detalhadamente.',
        report_desc: 'Descrição do relatório',
        detail_desc: 'Descreva seu relatório em detalhes',
        call: 'Informações de contato',
        option: 'Opcional',
        submit: 'Enviar',
        remark:'Observação',
        cancel: 'Cancelar',
        take: 'Tire sua foto real',
        lan: 'Longitude',
        lon: 'Latitude',
        device: 'Modelo do dispositivo',
        time: 'Duração do vídeo'
    },
    // 西班牙
    'es': {
        input_id: 'Haga clic aquí para ingresar una identificación con foto',
        report: 'Reporte',
        qrcode: 'Código QR',
        shoot_time: 'Fecha de disparo',
        address: 'Dirección',
        time_visited: 'Puntos de vista',
        download: 'Descargar aplicación',
        share: 'Cuota',
        embed: 'Empotrar',
        question: '¿Quieres compartir esta foto en tus redes sociales? ¡Simplemente copie el enlace a continuación!',
        copy_link: 'Copiar link',
        copy: 'Copiar',
        embed_code: '"¿Quieres incrustar esta foto en tu sitio web o blog?Simplemente ingrese el código de inserción a continuación y ¡listo!',
        code: 'Código de inserción',
        response: 'Sensible',
        wait_48: 'Su informe será manejado dentro de las 48 horas. Por favor envíe su queja en detalle.',
        report_desc: 'Descripción del reporte',
        detail_desc: 'Por favor, describa su informe en detalle',
        call: 'Información del contacto',
        option: 'Opcional',
        submit: 'Enviar',
        remark:'Observación',
        cancel: 'Cancelar',
        take: 'Toma tu foto real',
        lan: 'Longitud',
        lon: 'Latitud',
        device: 'Modelo de dispositivo',
        time: 'Duração do vídeo'
    },
    // 印度尼西亚
    'in': {
        input_id: 'Klik di sini untuk memasukkan ID foto',
        report: 'Laporan',
        qrcode: 'Kode QR',
        shoot_time: 'Tanggal Pemotretan',
        address: 'Alamat',
        time_visited: 'Tampilan',
        download: 'Unduh Aplikasi',
        share: 'Membagikan',
        embed: 'Menanamkan',
        question: 'Ingin membagikan foto ini ke media sosial Anda? Cukup salin tautan di bawah ini!',
        copy_link: 'Salin tautan',
        copy: 'Salinan',
        embed_code: 'Ingin menyematkan foto ini di situs web atau blog Anda?Cukup masukkan kode sematan di bawah dan selesai!',
        code: 'Sematkan Kode',
        response: 'Responsif',
        wait_48: 'Laporan Anda akan ditangani dalam waktu 48 jam. Silakan kirimkan keluhan Anda secara rinci.',
        report_desc: 'Deskripsi laporan',
        detail_desc: 'Jelaskan laporan Anda secara rinci',
        call: 'Kontak informasi',
        option: 'Opsional',
        submit: 'Kirim',
        remark:'Komentar',
        cancel: 'Membatalkan',
        take: 'Ambil Foto Asli Anda',
        lan: 'Garis bujur',
        lon: 'Garis Lintang',
        device: 'Model Perangkat',
        time: 'Durasi Video'
    },
    // 菲律宾
    'fil': {
        input_id: 'Mag-click dito para maglagay ng photo ID',
        report: 'Ulat',
        qrcode: 'QR Code',
        shoot_time: 'Petsa ng Pagbaril',
        address: 'Address',
        time_visited: 'Mga view',
        download: 'I-download ang App',
        share: 'Ibahagi',
        embed: 'I-embed',
        question: 'Gusto mo bang ibahagi ang larawang ito sa iyong social media? Kopyahin lang ang link sa ibaba!',
        copy_link: 'Kopyahin ang Link',
        copy: 'Kopya',
        embed_code: 'Gusto mo bang i-embed ang larawang ito sa iyong website o blog?Ilagay lang ang embed code sa ibaba at tapos ka na!',
        code: 'I-embed ang Code',
        response: 'Tumutugon',
        wait_48: 'Hahawakan ang iyong ulat sa loob ng 48 oras. Mangyaring isumite ang iyong reklamo nang detalyado.',
        report_desc: 'Paglalarawan ng Ulat',
        detail_desc: 'Pakilarawan nang detalyado ang iyong ulat',
        call: 'Impormasyon sa Pakikipag-ugnayan',
        option: 'Opsyonal',
        submit: 'Ipasa',
        remark:'Puna',
        cancel: 'Kanselahin',
        take: 'Kunin ang Iyong Tunay na Larawan',
        lan: 'Longitude',
        lon: 'Latitude',
        device: 'Modelo ng Device',
        time: 'Tagal ng Video'
    },
    // 日语
    'ja': {
        input_id: '写真付き身分証明書の入力はこちら',
        report: '報告',
        qrcode: 'QRコード',
        shoot_time: '撮影日',
        address: '住所',
        time_visited: 'ビュー',
        download: 'アプリをダウンロードする',
        share: 'シェア',
        embed: '埋め込む',
        question: 'この写真をソーシャル メディアで共有したいですか？以下のリンクをコピペするだけ！',
        copy_link: 'リンクをコピーする',
        copy: 'コピー',
        embed_code: '「この写真をあなたのウェブサイトやブログに埋め込みたいですか?以下の埋め込みコードをドロップするだけで完了です!」',
        code: '埋め込みコード',
        response: 'レスポンシブ',
        wait_48: '報告は 48 時間以内に処理されます。苦情を詳細に提出してください。',
        report_desc: 'レポートの説明',
        detail_desc: 'レポートを詳しく説明してください',
        call: '連絡先',
        option: 'オプション',
        submit: '送信',
        remark:'述べる',
        cancel: 'キャンセル',
        take: 'あなたの本当の写真を撮る',
        lan: '経度',
        lon: '緯度',
        device: 'デバイスモデル',
        time: 'ビデオの長さ'
    },
    // 泰语
    'th': {
        input_id: 'คลิกที่นี่เพื่อป้อน ID ภาพถ่าย',
        report: 'รายงาน',
        qrcode: 'คิวอาร์โค้ด',
        shoot_time: 'วันที่ถ่ายทำ',
        address: 'ที่อยู่',
        time_visited: 'มุมมอง',
        download: 'ดาวน์โหลดแอป',
        share: 'แบ่งปัน',
        embed: 'ฝัง',
        question: 'ต้องการแชร์รูปภาพนี้ไปยังโซเชียลมีเดียของคุณหรือไม่ เพียงคัดลอกลิงค์ด้านล่าง!',
        copy_link: 'คัดลอกลิงค์',
        copy: 'สำเนา',
        embed_code: 'ต้องการฝังรูปภาพนี้บนเว็บไซต์หรือบล็อกของคุณหรือไม่?เพียงวางโค้ดสำหรับฝังด้านล่าง เท่านี้ก็เสร็จเรียบร้อย!',
        code: 'รหัสฝัง',
        response: 'ตอบสนอง',
        wait_48: 'รายงานของคุณจะได้รับการจัดการภายใน 48 ชั่วโมง กรุณาส่งการร้องเรียนของคุณโดยละเอียด',
        report_desc: 'คำอธิบายรายงาน',
        detail_desc: 'โปรดอธิบายรายงานของคุณโดยละเอียด',
        call: 'ข้อมูลติดต่อ',
        option: 'ไม่จำเป็น',
        submit: 'ส่ง',
        remark:'ข้อสังเกต',
        cancel: 'ยกเลิก',
        take: 'ถ่ายภาพจริงของคุณ',
        lan: 'ลองจิจูด',
        lon: 'ละติจูด',
        device: 'รุ่นอุปกรณ์',
        time: 'ระยะเวลาของวิดีโอ'
    },
    // 马来西亚
    'ms': {
        input_id: 'Klik di sini untuk memasukkan ID foto',
        report: 'Laporan',
        qrcode: 'Kod QR',
        shoot_time: 'Tarikh Penggambaran',
        address: 'Alamat',
        time_visited: 'Pandangan',
        download: 'Ambil Foto Sebenar Anda',
        share: 'Muat turun Apl',
        embed: 'Kongsi',
        question: 'Benamkan',
        copy_link: 'Salin pautan',
        copy: 'Salinan',
        embed_code: 'Ingin membenamkan foto ini di tapak web atau blog anda?Hanya masukkan kod benam di bawah dan anda sudah selesai!',
        code: 'Benamkan Kod',
        response: 'Responsif',
        wait_48: 'Laporan anda akan dikendalikan dalam masa 48 jam. Sila kemukakan aduan anda secara terperinci.',
        report_desc: 'Penerangan Laporan',
        detail_desc: 'Sila terangkan laporan anda secara terperinci',
        call: 'Maklumat perhubungan',
        option: 'Pilihan',
        submit: 'Hantar',
        remark:'Teguran',
        cancel: 'Batal',
        take: 'Ambil Foto Sebenar Anda',
        lan: 'Longitud',
        lon: 'Latitud',
        device: 'Model Peranti',
        time: 'Tempoh Video'
    }
}

export default language;