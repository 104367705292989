import React from 'react';
// 引入接口
import "../mock/index";
import api from "../services/global.js";
import Language from '../commons/language'
// 例子
// p 是  props 类型
// s 是  state 类型
interface AppProps {
  openDia: Function,
}
interface AppState {
  count: number;
  history: string[];
  lan: string,
  showHis: boolean
}
export default class Header extends React.Component<AppProps, AppState> {
  public input: any;

  constructor(prop: any) {
    super(prop);

    var lang: string = navigator.language
    lang = lang.substring(0, 2);//截取lang前2位字符
    this.state = {
      count: 0,
      history: [],
      lan: lang,
      showHis: false
    }
  }

  componentDidMount() {
    this.setState({
      history: window.localStorage.getItem('s_history') === null ? [] : JSON.parse(window.localStorage.getItem('s_history') as string)
    })
  }

  jump() {
    window.location.href = './'
  }

  focus() {
    if (this.state.history.length > 0) {
      this.setState({
        showHis: true
      })
    }
  }

  delete(e: any) {
    this.setState({
      history: [...this.state.history.filter((item) => {
        return item != e.currentTarget.getAttribute("data-id")
      })]
    }, () => {
      this.saveLocal()
    })
  }

  fill(e: any) {
    this.input.value = e.currentTarget.getAttribute("data-id")
    window.location.href = './search?code=' + e.currentTarget.getAttribute("data-id");
  }


  saveLocal() {
    this.setState({
      showHis: false
    })
    window.localStorage.setItem('s_history', JSON.stringify(this.state.history))
  }

  Search(e: any) {
    if (e.key === "Enter") {
      const inpVal = this.input.value;
      if (this.state.history.length === 0) {
        // 没有
        const his: string[] = [];
        his.push(inpVal);
        this.setState({
          history: [...his]
        }, () => {
          this.saveLocal()
        })

      } else {
        if (this.state.history.indexOf('' + inpVal) < 0) {
          if (this.state.history.length > 10) {
            let arr = this.state.history.filter((item, index) => {
              return index > 0;
            })
            this.setState({
              history: [...arr, inpVal]
            }, () => {
              this.saveLocal()
            })
          } else {
            this.setState({
              history: [...this.state.history, inpVal]
            }, () => {
              this.saveLocal()
            })
          }

        }
      }
      window.location.href = './search?code=' + inpVal;
    }
  }

  render() {
    {/* dialog  */ }
    return <div className="h-[15.58rem] lg:h-[8.33rem] lg:w-full bg-black lg:flex lg:justify-center">
      <div className="lg:flex lg:justify-between lg:w-3/5">
        <div className=" text-center lg:h-full lg:flex lg:flex-col lg:justify-center" onClick={() => this.jump()}>
          <p className=" text-white font-bold text-[2.5rem] pt-[2rem] lg:pt-[0] cursor-pointer">RealCam</p>
          <p className=" text-white text-2xl font-bold pt-[1rem] lg:pt-[0] cursor-pointer">100% Real Photo!</p>
        </div>
        <div className="lg:h-full flex flex-row lg:flex-col justify-center relative">
          <div className=" w-[31.67rem] h-[4.17rem] bg-gray-600  flex flex-row lg:w-[26.67rem] lg:h-[4.17rem] mt-[1rem]">
            <span className='h-full flex flex-col justify-center'>
              <img src="https://storage.googleapis.com/assets.realc.am/front/images/search.png" className="w-5 h-5 ml-4" alt="" />
            </span>
            <span className='h-full flex flex-col justify-center ml-4' style={{ fontSize: '1.33rem', color: '#CCCCCC' }}>
              <input onFocus={() => this.focus()} autoComplete="on" type="text" className='w-full h-full outline-none border-0 bg-gray-600' placeholder={Language[this.state.lan].input_id} ref={input => this.input = input} onKeyDown={(e) => this.Search(e)} />
            </span>
          </div>
          {
            this.state.showHis ? <div style={{ borderTop: '1px solid #f1f1f1' }} className='absolute top-[5rem] lg:top-[7rem] bg-white w-[92%] lg:w-full h-auto p-[1rem] overflow-y-auto'>
              <ul>
                {
                  this.state.history.map((item) => {
                    return <li key={item} className="flex justify-between text-[1.3rem] h-[10%]">
                      <span className=' cursor-pointer' onClick={(e) => this.fill(e)} data-id={item}>
                        {
                          item
                        }
                      </span>
                      <span className=' text-red-500 cursor-pointer' data-id={item} onClick={(e) => this.delete(e)}>
                        x
                      </span>
                    </li>
                  })
                }
                {/* <li className="flex justify-between text-[1.3rem] h-[10%]">
                            <span className=' cursor-pointer'>
                                888
                            </span>
                            <span className=' text-red-500 cursor-pointer'>
                                x
                            </span>
                        </li> */}
              </ul>
            </div> : <></>
          }
        </div>
        {/* <div className='lg:hidden pl-[1.5rem] pr-[1.5rem] mt-[1rem]'>
          <p className='text-white text-[1.33rem]'>Real Camera ID:</p>
          <p className='text-white flex justify-between'><span className='text-[2rem] inline-block w-1/2'>{this.props.code}</span> <span className='text-[1.58rem] text-[#1975FF]' onClick={() => this.report()}>{Language[this.state.lan].report}</span></p>
        </div> */}
      </div>
    </div>
  }
}

