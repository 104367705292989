import { Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import Search from './pages/Search';
import Report from './pages/Report';
import './App.css';
function App(props:any) {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Search />}>
        </Route>
        <Route path="/search" {...props} element={<Index />}>
        </Route>
        <Route path="/report"  element={<Report />}>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
