import React from 'react';
// 引入接口
import "../mock/index";
import  api, {fetchGet, fetchPost } from "../services/global.js";
import language from '../commons/language';
// 例子
// p 是  props 类型
// s 是  state 类型
interface AppProps {
    type: number;
    openDia: Function;
    code: string | boolean
}
interface AppState {
    count: number;
    age: number;
    lan: string,
    url: string
}
export default class Dialog extends React.Component<AppProps, AppState> {
    public input: any;
    public input2: any;

    constructor(prop: any) {
        super(prop);
        var lang:string = navigator.language
        lang = lang.substring(0, 2);//截取lang前2位字符
        this.state = {
            count: 0,
            age: 52,
            lan: lang,
            url: ''
        }
    }


    componentDidMount() {
        fetchGet("https://api.realc.am/api/web/list?code=" + this.props.code).then(res => {
            const web = res.web_site;
            this.setState({
                url: `${web}${this.props.code}`
            })
        });
    }

    close() {
        const { openDia } = this.props;
        openDia({
            showDialog: false,
            type: 0
        })
    }

    copy(context:string) {

        var aux = document.createElement("input"); 
        aux.setAttribute("value",context); 
        document.body.appendChild(aux); 
        aux.select();
        document.execCommand("copy"); 
        document.body.removeChild(aux);
        alert("success");
    }

    submit() {
        const inpVal = this.input.value;
        const inpVal2 = this.input2.value;
        if(inpVal == '' || inpVal2 == '') return false;
        let param = {
            report_code: this.props.code,
            content:inpVal,
            contact: inpVal2
        }
        fetchPost('https://api.realc.am/api/web/report',JSON.stringify(param),).then(res => {
            alert('success');
            const { openDia } = this.props;
            openDia({
                showDialog: false,
                type: 0
            })
        });
    }

    render() {
        {/* dialog  */ }

        let dialog;
        if (this.props.type === 0) {
            dialog = <div>
                <div className='flex flex-col items-center w-full h-full absolute z-30 top-[15rem]'>
                    <div className=" w-[29rem] lg:w-rem40 h-auto bg-white pt-7 pl-10 pb-12 pr-10">
                        <div className="flex justify-between border-b border-trueGray-200">
                            <span className="font-bold pb-7 text-2xl">{language[this.state.lan].report}</span>
                            <span>
                                <img className="w-5 h-5 cursor-pointer" src="https://storage.googleapis.com/assets.realc.am/front/images/dia_close.png" onClick={() => this.close()} />
                            </span>
                        </div>
                        <div className="pt-7">
                            <span className="font-bold text-2xl">{language[this.state.lan].wait_48}</span>
                        </div>
                        <div className="pt-10">
                            <div className="w-full h-48 bg-[#F5F5F5]">
                                <textarea className="w-full h-full p-2 outline-none border-0 bg-[#F5F5F5]" placeholder={language[this.state.lan].detail_desc} ref={input => this.input = input}></textarea>
                            </div>
                            <div className="w-full h-20 bg-[#F5F5F5] mt-5">
                                <input type="text" className="pl-2 w-full h-full outline-none border-0 bg-[#F5F5F5]" placeholder={language[this.state.lan].call} ref={input => this.input2 = input}/>
                            </div>
                        </div>
                        <div className='flex justify-end pt-[1rem]'>
                            <span className=" border-gray-400 text-black p-3 mr-10 cursor-pointer">{language[this.state.lan].cancel}</span>
                            <span className=" bg-btn-color text-white p-3 cursor-pointer" onClick={() => this.submit()}>{language[this.state.lan].submit}</span>
                        </div>
                    </div>
                </div>
                <div className='w-full h-full opacity-40 absolute bg-black'>
                </div>
            </div>
        } else if (this.props.type === 1) {
            dialog = <div>
                <div className='flex flex-col items-center w-full h-full absolute z-30 lg:top-11 top-[20rem]'>
                    <div className=" w-[29rem] lg:w-rem40 h-auto bg-white pt-7 pl-10 pb-12 pr-10">
                        <div className="flex justify-between border-b border-trueGray-200 pt-7">
                            <span className="font-bold pb-7 text-2xl">{language[this.state.lan].share} </span>
                            <span>
                                <img className="w-5 h-5 cursor-pointer" src="https://storage.googleapis.com/assets.realc.am/front/images/dia_close.png" onClick={() => this.close()} />
                            </span>
                        </div>
                        <div className="pt-7">
                            <span className="font-bold text-2xl">{language[this.state.lan].question}</span>
                        </div>
                        <div className="pt-10 flex">
                            <span className='text-[1.5rem] font-bold'>
                                {language[this.state.lan].copy_link}
                            </span>
                            <span className='ml-[1.25rem]'>
                                <img src="https://storage.googleapis.com/assets.realc.am/front/images/commuty.png" className='w-[12.67remrem] h-[2.33rem]' />
                            </span>
                        </div>
                        <div className='hidden mt-[2rem] lg:flex'>
                            <div className='text-[1.5rem] w-[27.83rem] h-[4.17rem]  text-white'>
                                <input type="text" className='bg-black w-full h-full pl-[1rem]' readOnly value={this.state.url} />
                            </div>
                            <div className='ml-[1.25rem] text-[#1975FF] text-[1.67rem] font-bold flex items-center cursor-pointer' onClick={() => this.copy(this.state.url)}>
                                {language[this.state.lan].copy}
                            </div>
                        </div>

                        <div className='mt-[1rem] lg:hidden'>
                            <div className='text-[#1975FF] text-[1.33rem]'>
                                {this.state.url}
                            </div>
                            <div className='w-full h-[3.33rem] bg-[#1975FF] text-center text-white text-[1.33rem] leading-[3.33rem] mt-[1rem]' onClick={() => this.copy(this.state.url)}>
                                {language[this.state.lan].copy}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full h-full opacity-40 absolute bg-black'>
                </div>
            </div>
        } else {
            dialog = <div>
                <div className='flex flex-col items-center w-full h-full absolute z-30 lg:top-11 top-[20rem]'>
                    <div className=" w-[29rem] lg:w-rem40 h-auto bg-white pt-7 pl-10 pb-12 pr-10">
                        <div className="flex justify-between border-b border-trueGray-200 pt-7">
                            <span className="font-bold pb-7 text-2xl">{language[this.state.lan].embed} </span>
                            <span>
                                <img className="w-5 h-5 cursor-pointer" src="https://storage.googleapis.com/assets.realc.am/front/images/dia_close.png" onClick={() => this.close()} />
                            </span>
                        </div>
                        <div className="pt-7">
                            <span className="font-bold text-2xl">{language[this.state.lan].embed_code}</span>
                        </div>
                        <div className="pt-10 flex">
                            <span className='text-[1.5rem] font-bold'>
                                {language[this.state.lan].code}
                            </span>
                            <span className='ml-[1.25rem]'>

                            </span>
                        </div>
                        <div className='hidden mt-[2rem] lg:flex'>
                            <div className='text-[1.5rem] w-[27.83rem] h-[4.17rem]  text-white'>
                                <input type="text" className='bg-black w-full h-full pl-[1rem]' readOnly value={'<img src="' + this.state.url + '"/>'} />
                            </div>
                            <div className='ml-[1.25rem] text-[#1975FF] text-[1.67rem] font-bold flex items-center cursor-pointer' onClick={() => this.copy('<img src="' + this.state.url + '"/>')}>
                                {language[this.state.lan].copy}
                            </div>
                        </div>

                        <div className='mt-[1rem] lg:hidden'>
                            <div className='text-[#1975FF] text-[1.33rem]'>
                                {this.state.url}
                            </div>
                            <div className='w-full h-[3.33rem] bg-[#1975FF] text-center text-white text-[1.33rem] leading-[3.33rem] mt-[1rem]' onClick={() => this.copy('<img src="' + this.state.url + '"/>')}>
                             {language[this.state.lan].copy}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full h-full opacity-40 absolute bg-black'>
                </div>
            </div>
        }
        return <div className='w-full h-full fixed top-0'>
            {dialog}
        </div>


    }
}

