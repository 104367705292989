import React from 'react';
// 引入接口
import "../mock/index";
import Language from '../commons/language'
import api from "../services/global.js";
// 例子
// p 是  props 类型
// s 是  state 类型
interface AppProps {
  openDia: Function
}
interface AppState {
    count: number;
    age: number;
    lan: string
}
export default class RightContainer extends React.Component<AppProps, AppState> {
    constructor(prop: any) {
        super(prop);
        var lang:string = navigator.language
        lang = lang.substring(0, 2);//截取lang前2位字符
        this.state = {
            count: 0,
            age: 52,
            lan: lang
        }
    }

    componentDidMount() {
        

    }

    getQueryVariable(variable:string) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
      }
      return (false);
    }

    jump() {

    }
  

    open(type:number) {
      const { openDia } = this.props;
      openDia({
        showDialog: true,
        type: type,
        code : this.getQueryVariable('code')
      })
    }

    render() {
        {/* dialog  */ }
        return <div style={{marginTop: '0.75rem', borderLeft: '1px solid #E5E5E5'}} className="pl-5 pb-[2rem]">
        <p className=' text-2xl font-bold'>{Language[this.state.lan].take}</p>
        <div onClick={() => this.jump()} style={{ backgroundColor: '#EBEBEB', marginTop: '1.67rem' }} className='lg:w-[26.67rem] w-[96%]'>
          <img src="https://storage.googleapis.com/assets.realc.am/front/images/ads.png"/>
        </div>
        <div className='hidden lg:flex justify-between space-x-5 mt-[2rem]'>
                <img className='w-[11.67rem] cursor-pointer' src="https://storage.googleapis.com/assets.realc.am/front/images/apple.png" onClick={() => this.jump()}/>
                <img className='w-[11.67rem] cursor-pointer' src="https://storage.googleapis.com/assets.realc.am/front/images/google.png" onClick={() => this.jump()}/>
            </div>
        <div style={{ backgroundColor: '#1975FF', height: '4.17rem', lineHeight: '4.17rem' }} className='text-white lg:w-full text-center text-2xl font-bold mt-10 w-[96%] lg:hidden'>{Language[this.state.lan].download}</div>
        <div className='flex justify-center' style={{marginTop: '2.25rem'}}>
          <div className=' cursor-pointer' style={{marginRight: '4.665rem'}} onClick={() => this.open(1)}>
            <div>
              <img src="https://storage.googleapis.com/assets.realc.am/front/images/send.png" alt="" className=' w-20 h-20'/>
            </div>
            <div className='text-center text-2xl font-bold' style={{marginTop: '1.33rem'}}>{Language[this.state.lan].share}</div>
          </div>
          <div className='cursor-pointer' style={{marginLeft: '4.665rem'}} onClick={() => this.open(2)}>
            <div>
              <img src="https://storage.googleapis.com/assets.realc.am/front/images/code.png" alt="" className=' w-20 h-20'/>
            </div>
            <div className='text-center text-2xl font-bold' style={{marginTop: '1.33rem'}}>{Language[this.state.lan].embed}</div>
          </div>
        </div>
      </div>
    }
}

