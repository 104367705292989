import React from 'react';
import Dialog from '../components/Dialog';
import Header from '../components/Header';
import LeftContainer from '../components/LeftContainer';
import RightContainer from '../components/RightContainer';

interface AppProps {

}

interface DialogState {
  showDialog: boolean,
  type: number;
  code: string | false;
}

type obj = {
  showDialog: boolean;
  type: number;
  code?: any
}

export default class Index extends React.Component<AppProps, DialogState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showDialog: false,
      type: 0,
      code: this.getQueryVariable('code')
    }
    this.openDia = this.openDia.bind(this);
  }

  componentDidMount() {
  }

  openDia(obj: obj) {
    this.setState({
      showDialog: obj.showDialog,
      type: obj.type,
      code: obj.code
    })
  }

  getQueryVariable(variable:string) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
  }

  render() {
    {/* dialog  */ }
    return <div>
      <Header openDia={this.openDia}/>
      {/* 内容 */}
      <div className="w-full lg:flex lg:justify-center">
        <div className="lg:flex lg:justify-between lg:w-3/5 lg:mt-[3.33rem]">
          <LeftContainer openDia={this.openDia} code={this.state.code}/>
          <RightContainer openDia={this.openDia} />
        </div>
      </div>
      {
        this.state.showDialog ? <Dialog code={this.state.code} openDia={this.openDia} type={this.state.type} /> : <></>
      }

    </div>
  }
}

