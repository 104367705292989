import Mock from "mockjs"; // 引入mockjs
const Random = Mock.Random; // Mock.Random 是一个工具类，用于生成各种随机数据

let data = []; // 用于接受生成数据的数组
let arr = [11,21,31,41,51]
for (let i = 0; i < 10; i++) {
  let template = {
    camera_id: Random.guid(), // 照片id
    qrcode_url: Random.string(2, 10), // 二维码图片地址
    shoot_time: Random.date(), // 拍摄时间
    // shoot_time: Random.date(), // 照片id
    lat_long: Random.pick(arr,0, 2), // 经度纬度
    weather: Random.integer(), // 气温
    address: Random.city(), // 地址
    remark: Random.name(), // 记录
    time_visited: Random.integer(0,2000), // 观看记录
  };
  data.push(template);
}
const ramNum = Math.random()*5;
Mock.mock("/data/getInfo", "post", {
  code: 200,
  data: data
}); // 


Mock.mock("/data/index", "post", {
  code: 200,
  data
}); // 