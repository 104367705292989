import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import QRCode from 'qrcode'
import Language from '../commons/language'
// 引入接口
import "../mock/index";
import api, { fetchGet, fetchPost } from "../services/global.js";
import userEvent from '@testing-library/user-event';
const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: 500.3257594,
    lng: 120.7099807
};
type AppProps = {
    openDia: Function;
    code: string | boolean
}

const Child: React.FC<AppProps> = (props) => {
    const [infos, setInfo] = useState<any>([])
    const [pic, setPic] = useState<any>(true)
    const [lan, setLan] = useState<any>('')
    const { openDia, code } = props;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDqV0qNkfO5yYq_3GnPUecEEKxQqCeAZ1Y"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map: any) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        // setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    useEffect(() => {
        fetchGet("https://api.realc.am/api/web/list?code=" + code).then(res => {
            const web = res.web_site;
            const data: any = []
            res.list.forEach(async (item: any) => {
                const url = await createQrcode(`${web}${item.img_code}`)
                item.qrcode = url
                data.push(item)
            })
            setInfo(data)
        });


        var lang: string = navigator.language
        lang = lang.substring(0, 2);//截取lang前2位字符
        setLan(lang)
    }, []);

    function createQrcode(str: string) {
        return QRCode.toDataURL(str)
            .then(url => {
                return url;
            })
    }

    function report(code:any) {
        openDia({
            showDialog: true,
            type: 0,
            code: code
        })
    }

    function reportPhone(code:string) {
        window.location.href = '/report?code=' + code
    }

    function changepic(e: any, index: number) {
        const small = infos[index].sub_img_url
        const big = infos[index].img_url
        if (pic) {
            e.currentTarget.setAttribute('src', big);
           e.currentTarget.previousSibling?.setAttribute('src', small);
            setPic(false)
        } else {
            e.currentTarget.setAttribute('src', small);
           e.currentTarget.previousSibling?.setAttribute('src', big);
            setPic(true)
        }
    }

    return (
        <div className='lg:pr-5'>
            {
                infos.map((info: any, index: number) => {
                    return <div key={info.img_code} className='lg:pr-10'>
                        <div className='lg:hidden pl-[1.5rem] pt-[1rem] pb-[1rem] pr-[1.5rem] bg-[black]'>
                            <p className='text-white text-[1.33rem]'>Real Camera ID:</p>
                            <p className='text-white flex justify-between'><span className='text-[2rem] inline-block w-1/2'>{info.img_code}</span> <span className='text-[1.58rem] text-[#1975FF]' onClick={() => reportPhone(info.img_code)}>{Language[lan].report}</span></p>
                        </div>
                        <p className='hidden lg:flex lg:bg-[white] justify-between pb-5 border-b pt-8'>
                            <span style={{ fontSize: '2rem' }} className=" font-bold">Real Camera ID: {info.img_code}</span>
                            <span style={{ color: '#1975FF', fontSize: '1.33rem' }} className="flex flex-col justify-center cursor-pointer" data-id={info.img_code} onClick={() => report(info.img_code)}>{Language[lan].report}</span>
                        </p>
                        <div className='lg:flex lg:flex-nowrap lg:justify-between w-full lg:pb-[2rem] lg:border-b lg:border-[rgb(229, 229, 229)] pb-[2rem]'>
                            {/* 大的 */}
                            {/* 照片 */}
                            <div className="lg:flex lg:mt-10 w-full lg:w-[39.17rem] lg:h-[52.25rem] relative">
                                {
                                    info.type == 1 ? <div>
                                        <img id='bigpic' className='w-full lg:w-[39.17rem] lg:h-[52.25rem]' data-id={info.sub_img_url} src={info.img_url} alt="" />
                                        {
                                            info.sub_img_url !== '' ? <img onClick={(e) => changepic(e, index)} style={{ border: '5px solid black' }} className='absolute top-0 right-0 w-[12.17rem] h-[16.25rem]' data-id={info.img_url} src={info.sub_img_url} /> : <></>
                                        }
                                    </div>
                                        : <video className='w-full lg:w-[39.17rem] lg:h-[52.25rem]' controls>
                                            <source src={info.video_url}></source>
                                        </video>
                                }

                                <div className='h-auto bg-[rgba(0,0,0,0.39)] absolute bottom-20 w-[92%] ml-[4%] flex flex-row pl-[1rem] pr-[1rem]' id='yuan'>
                                    <div className='pt-[1rem] pb-[1rem] w-2/12'>
                                        <img className='w-full' src={info.qrcode} />
                                    </div>
                                    <div className='ml-[0.5rem] w-10/12'>
                                        <p className='text-white text-[1.08rem] mt-[0.5rem]'>{info.shooting_time}</p>
                                        {
                                            info.lng === '' && info.lat === '' ? <></> : <p className='text-white text-[1.08rem]'>{info.address}{Language[lan].lan} {info.lat} {Language[lan].lon}{info.lng}</p>
                                        }
                                        <p className='text-white text-[1.08rem] pb-2'>
                                            {
                                                info.remark
                                            }
                                        </p>
                                        <p className='text-white text-[1.08rem] pb-2'>
                                            {
                                                info.mobile_type
                                            }
                                        </p>
                                        <p className='text-white text-[1.08rem] pb-2'>RealCamera ID:{info.img_code}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '34.17rem' }} className='lg:ml-10 pl-5'>
                                <p className=' text-2xl font-bold mt-10'>{Language[lan].qrcode}</p>
                                <img className="w-32 h-32 mt-3" src={info.qrcode} alt="qr code" />
                                <p className=' text-2xl font-bold mt-10'>RealCamera ID:</p>
                                <p className=' text-sm mt-3'>{info.img_code}</p>
                                <p className=' text-2xl font-bold mt-10'>{Language[lan].shoot_time}:</p>
                                <p className=' text-sm mt-3'>{info.shooting_time}</p>
                                {
                                    info.lat === '' && info.lng === '' ? <></> : <div>
                                        <p className=' text-2xl font-bold mt-10'>{Language[lan].lan}/{Language[lan].lon}:</p>
                                        <p className=' text-sm mt-3'>{info.lat}/{info.lng}</p>
                                        <div className="" style={{ width: '26.67rem', height: '11rem' }}>
                                            {
                                                isLoaded ? (<GoogleMap
                                                    id='google-map-script'
                                                    mapContainerStyle={containerStyle}
                                                    center={{
                                                        lat: Number(info.lat),
                                                        lng: Number(info.lng)
                                                    }}
                                                    clickableIcons={true}
                                                    zoom={15}
                                                    onLoad={onLoad}
                                                    onUnmount={onUnmount}
                                                >
                                                    <></>
                                                </GoogleMap>) : <></>
                                            }
                                        </div>

                                    </div>
                                }

                                {
                                    info.address === '' ? <></> : <div>
                                        <p className=' text-2xl font-bold mt-10'>{Language[lan].address}:</p>
                                        <p className=' text-sm mt-3'>{info.address}</p>
                                    </div>
                                }


                                {
                                    info.remark === '' ? <></> : <div>
                                        <p className=' text-2xl font-bold mt-10'>{Language[lan].remark}:</p>
                                        <p className=' text-sm mt-3'>{info.remark}</p>
                                    </div>
                                }

                                {
                                    info.mobile_type === '' ? <></> : <div>
                                        <p className=' text-2xl font-bold mt-10'>{Language[lan].device}:</p>
                                        <p className=' text-sm mt-3'>{info.mobile_type}</p>
                                    </div>
                                }

                                <p className=' text-2xl font-bold mt-10'>{Language[lan].time_visited}:</p>
                                <p className=' text-sm mt-3'>{info.click_num}</p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default Child;