import React from 'react';
import Language from '../commons/language'
interface AppProps {
}
interface AppState {
    lan: string;
    history: string[];
    showHis: boolean
}
export default class Search extends React.Component<AppProps, AppState>  {
    public input: any;
    constructor(prop: any) {
        super(prop);
        var lang: string = navigator.language
        lang = lang.substring(0, 2);//截取lang前2位字符
        this.state = {
            lan: lang,
            history: [],
            showHis: false
        }
        this.Search.bind(this);
    }

    componentDidMount() {
        this.setState({
            history: window.localStorage.getItem('s_history') === null ? [] : JSON.parse(window.localStorage.getItem('s_history') as string)
        })
    }

    Search(e: any) {
        if (e.key === "Enter") {
            const inpVal = this.input.value;
            if (this.state.history.length === 0) {
                // 没有
                const his: string[] = [];
                his.push(inpVal);
                this.setState({
                    history: [...his]
                }, () => {
                    this.saveLocal()
                })

            } else {
                if (this.state.history.indexOf('' + inpVal) < 0) {
                    if (this.state.history.length > 10) {
                        let arr = this.state.history.filter((item, index) => {
                            return index > 0;
                        })
                        this.setState({
                            history: [...arr, inpVal]
                        }, () => {
                            this.saveLocal()
                        })
                    } else {
                        this.setState({
                            history: [...this.state.history, inpVal]
                        }, () => {
                            this.saveLocal()
                        })
                    }

                }
            }
            window.location.href = './search?code=' + inpVal;
        }


    }

    ClickType() {
        const inpVal = this.input.value;
        if (this.state.history.length === 0) {
            // 没有
            const his: string[] = [];
            his.push(inpVal);
            this.setState({
                history: [...his]
            }, () => {
                this.saveLocal()
            })

        } else {
            if (this.state.history.indexOf('' + inpVal) < 0) {
                if (this.state.history.length > 10) {
                    let arr = this.state.history.filter((item, index) => {
                        return index > 0;
                    })
                    this.setState({
                        history: [...arr, inpVal]
                    }, () => {
                        this.saveLocal()
                    })
                } else {
                    this.setState({
                        history: [...this.state.history, inpVal]
                    }, () => {
                        this.saveLocal()
                    })
                }

            }
        }
        window.location.href = './search?code=' + inpVal;
    }

    focus() {
        if (this.state.history.length > 0) {
            this.setState({
                showHis: true
            })
        }
    }

    saveLocal() {
        if (this.state.history.length === 0) {
            this.setState({
                showHis: false
            })
        }
        window.localStorage.setItem('s_history', JSON.stringify(this.state.history))
    }

    delete(e: any) {
        this.setState({
            history: [...this.state.history.filter((item) => {
                return item != e.currentTarget.getAttribute("data-id")
            })]
        }, () => {
            this.saveLocal()
        })
    }

    jump() {
        
    }

    fill(e: any) {
        this.input.value = e.currentTarget.getAttribute("data-id")
        window.location.href = './search?code=' + e.currentTarget.getAttribute("data-id");
    }

    render() {
        {/* dialog  */ }
        return <div className='w-screen h-screen flex justify-center items-center bg-searchbg bg-cover lg:bg-lite bg-sute bg-no-repeat'>
            <div className='hidden lg:flex space-x-5 absolute top-[2rem] right-[60rem]'>
                <img className='w-[11.67rem] cursor-pointer' src="https://storage.googleapis.com/assets.realc.am/front/images/apple.png" onClick={() => this.jump()}/>
                <img className='w-[11.67rem] cursor-pointer' src="https://storage.googleapis.com/assets.realc.am/front/images/google.png" onClick={() => this.jump()}/>
            </div>
            <img src="https://storage.googleapis.com/assets.realc.am/front/images/labelTop.png" className='fixed w-[24.33rem] h-[7.5rem] lg:w-label-t lg:h-label-t top-[18rem] lg:top-[22.83rem]' />
            <img src="https://storage.googleapis.com/assets.realc.am/front/images/label-sub.png" className='fixed w-[29.08rem] h-[5.5rem] lg:w-label-s lg:h-label-s top-[25rem] lg:top-[32.17rem]' />
            <div className='bg-white flex flex-row justify-start w-[27.67rem] lg:w-[66.67rem] h-[5rem] relative'>
                <div className='flex flex-col items-center justify-center ml-6'>
                    <img src="https://storage.googleapis.com/assets.realc.am/front/images/search-icon.png" style={{ height: '1.67rem', width: '1.67rem', cursor: 'pointer' }} onClick={() => this.ClickType()} />
                </div>
                <div className='w-10/12 ml-6 flex flex-col justify-center'>
                    <input placeholder={Language[this.state.lan].input_id} className='outline-none border-0' style={{ fontSize: '1.67rem' }} ref={input => this.input = input} onKeyDown={(e) => this.Search(e)} onFocus={() => this.focus()} />
                </div>
                {/* history */}
                {
                    this.state.showHis ? <div style={{ borderTop: '1px solid #f1f1f1' }} className='absolute top-[5rem] bg-white w-full h-auto p-[1rem] overflow-y-auto'>
                        <ul>
                            {
                                this.state.history.map((item) => {
                                    return <li key={item} className="flex justify-between text-[1.3rem] h-[10%]">
                                        <span className=' cursor-pointer' onClick={(e) => this.fill(e)} data-id={item}>
                                            {
                                                item
                                            }
                                        </span>
                                        <span className=' text-red-500 cursor-pointer' data-id={item} onClick={(e) => this.delete(e)}>
                                            x
                                        </span>
                                    </li>
                                })
                            }
                        </ul>
                    </div> : <></>
                }
            </div>
                <div className='fixed bottom-[1rem] flex justify-center space-x-6'>
                    <span><a href="https://api.realc.am/Agreement/UserProtocol" className='text-white'>Terms of use</a></span>
                    <span><a href=" https://api.realc.am/Agreement/PrivacyPolicy" className='text-white'>Privacy Policy</a></span>
                </div>
        </div>
    }
}

