import React from 'react';
import Language from '../commons/language'
import  api, {fetchGet, fetchPost } from "../services/global.js";
interface AppProps {
  }
  interface AppState {
    lan: string
  }
export default class Search extends React.Component<AppProps, AppState> {
    public input:any;
    public input2:any;
    constructor(prop: any) {
        super(prop);
        var lang: string = navigator.language
        lang = lang.substring(0, 2);//截取lang前2位字符
        this.state = {
            lan: lang
        }
    }

    componentDidMount() {
       
    }

    submit() {
        const inpVal = this.input.value;
        const inpVal2 = this.input2.value;
        if(inpVal == '' || inpVal2 == '') return false;
        fetchPost('https://api.realc.am/api/web/report',JSON.stringify({
            report_code: this.getQueryVariable('code'),
            content:inpVal,
            contact: inpVal2
        })).then(res => {
            alert('success');
        });
    }

    getQueryVariable(variable:string) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
      }

    render() {
        {/* dialog  */ }
        return  <div className='p-[1.5rem]'>
            <div onClick={() => window.history.go(-1)}>
                <img src="https://storage.googleapis.com/assets.realc.am/front/images/back.png" className='w-[1.5rem]'/>
            </div>
           <div className=' h-[25rem] bg-[#F5F5F5] p-[1.33rem] mt-[1rem]'>
                <textarea className='w-full h-full bg-[#F5F5F5] text-[1.33rem] text-[#CCCCCC] outline-none border-none' placeholder={Language[this.state.lan].detail_desc} ref={input => this.input = input} ></textarea>
           </div>

           <div className=' h-[4rem] bg-[#F5F5F5] mt-[1rem] p-[1.33rem]'>
                <input className='w-full h-full bg-[#F5F5F5] text-[1.33rem] text-[#CCCCCC] outline-none border-none' placeholder={Language[this.state.lan].call} ref={input => this.input2 = input} />
           </div>

           <div className='w-[91%] h-[3.67rem] bg-[#1975FF] text-[#FFFFFF] text-[1.33rem] text-center leading-[3.67rem] fixed bottom-[1rem] font-bold' onClick={() => this.submit()}>
                {Language[this.state.lan].submit}
           </div>
      </div>
    }
}

